
      @use 'variables';
      @use 'mixins';

      sb-sidebar {
        mat-nav-list.sidebar {
          padding: variables.$nav-list-padding-y variables.$nav-list-padding-x;

          .mat-mdc-list-item {
            // First level item
            @include mixins.nav-list-item();

            &.has-children {
              + mat-nav-list {
                height: 0; // By default subitems are hidden
                padding: 0;
                overflow: hidden;

                &.children-expanded {
                  height: auto;
                }

                // Second level item
                .mat-mdc-list-item {
                  margin-top: variables.$nav-item-space-between;
                  min-width: variables.$nav-subitem-min-width;
                  height: variables.$nav-subitem-height !important;

                  mat-icon.mat-mdc-list-item-icon svg {
                    height: variables.$nav-subitem-icon-size;
                    width: variables.$nav-subitem-icon-size;
                  }
                }
              }

              // If parent has active then style subitems
              &.force-active,
              &.active {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                // Children container
                + mat-nav-list {
                  height: auto;
                  padding: 0 variables.$nav-subitem-padding-x variables.$nav-subitem-space-between;
                  background-color: variables.$nav-item-background-color-active;
                  border-bottom-left-radius: variables.$nav-item-radius;
                  border-bottom-right-radius: variables.$nav-item-radius;

                  &.expanded {
                    padding-left: variables.$nav-subitem-padding-x-expanded;
                    padding-right: variables.$nav-subitem-padding-x-expanded;
                  }

                  // Second level item
                  .mat-mdc-list-item {
                    &.active {
                      //background-color: variables.$nav-item-background-color-active;
                      &::before {
                        opacity: variables.$nav-subitem-overlay-opacity-active;
                      }
                    }

                    // Hover status
                    &:hover {
                      &::before {
                        opacity: variables.$nav-subitem-overlay-opacity-hover;
                      }
                    }

                    // Focus status
                    &:hover:focus {
                      &::before {
                        opacity: variables.$nav-subitem-overlay-opacity-focus;
                      }
                    }

                    &:focus {
                      &::before {
                        opacity: 0;
                      }
                    }
                  }
                }
              }
            }

            & + .mat-mdc-list-item,
            & + mat-nav-list + .mat-mdc-list-item {
              margin-top: variables.$nav-item-space-between;
            }

            & ~ .spacer {
              height: variables.$nav-item-space-after;
            }
          }
        }
      }
    